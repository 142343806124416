import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `http://localhost:7037/api/v1`
      : `https://api.${process.env.REACT_APP_DOMAIN}/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axios;
